import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@yonderlabs-packages/ui';

const buttonVariants = cva(
  'first-letter:capitalize inline-flex gap-2 items-center justify-center rounded-md text-sm font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-yonder-400 focus:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        default: 'bg-zinc-800 text-white hover:bg-black active:neutral-600 data-[state=open]:bg-black-100',
        destructive:
          'bg-transparent border-[1.5px] border-red-400 text-red-400 hover:border-red-600 hover:text-red-600 focus:ring-red-400 data-[state=open]:bg-red-100',
        outline:
          'bg-transparent border-[1.5px] border-zinc-800 text-zinc-800 hover:border-black hover:text-black data-[state=open]:bg-transparent',
        // subtle:
        //   "bg-slate-100 text-slate-900 hover:bg-slate-200 dark:bg-slate-700 dark:text-slate-100",
        ghost:
          'bg-transparent hover:bg-zinc-100 data-[state=open]:bg-transparent dark:data-[state=open]:bg-transparent',
        // link: "bg-transparent dark:bg-transparent underline-offset-4 hover:underline text-slate-900 dark:text-slate-100 hover:bg-transparent dark:hover:bg-transparent",
      },
      size: {
        default: 'h-14 py-4 px-6 rounded-2xl',
        sm: 'h-10 py-2 px-4 rounded-xl',
        // lg: "h-11 px-8 rounded-md",
      },
      block: {
        true: 'w-full',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, block, ...props }, ref) => {
    return <button className={cn(buttonVariants({ variant, size, block, className }))} ref={ref} {...props} />;
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
export type { ButtonProps };
